import React from 'react';

export default function Contact(){
return (
    <div className="container">
          <header>
            <h2>Contact</h2>
          </header>

          <p>
            Getting in touch with me is pretty easy, either message me on my social media accounts or fill out this form.
          </p>

          <form method="post" action="https://formspree.io/maybokva">
            <div className="row">
              <div className="col-6 col-12-mobile">
                <input type="text" name="name" placeholder="Name" />
              </div>
              <div className="col-6 col-12-mobile">
                <input type="text" name="email" placeholder="Email" />
              </div>
              <div className="col-12">
                <textarea name="message" placeholder="Message" />
              </div>
              <div className="col-12">
                <input type="submit" value="Send Message" />
              </div>
            </div>
          </form>
          </div>
)}