import React from 'react';
import Scroll from '../components/Scroll';

export default function Main(){
    return (
        <div className="container">
            <header>
            <h2 className="alt">
               Welcome
            </h2>
            </header>
            <p><i>"Attitude is a small thing that makes a <strong>big</strong> difference."</i> - Winston Churchil</p>
            <footer>
            <Scroll type="id" element={'about'}>
                <a href="#about" className="button">
                About Me
                </a>
            </Scroll>
            </footer>
      </div>
    )
}