import React from 'react';
import aboutPic from '../assets/images/avatar.png';

export default function Portfolio(){
    return(
        
        <div className="container">
          <header>
            <h2>About Me</h2>
          </header>

          <a href="/#" className="picture featured">
            <img src={aboutPic} alt="" />
          </a>

          <p>
            Very passionate about programming, both learning and teaching. When I'm not reading up on whats new 
            in the software development field, I'm likely busy spending time with my family. 
          </p>
        </div>
    )}