import React from 'react';
import dsmhack from '../assets/images/dsmhack.png';
import tai from '../assets/images/tai-logo.svg'
import wya from '../assets/images/wya.webp';
export default function Portfolio() {
    return(
        <div className="container">
          <header>
            <h2>Fun Stuff I've Worked On</h2>
          </header>

          <div className="row">
            <div className="col-4 col-12-mobile project">
              <article className="item">
                <a href="https://www.dsmhack.org" className="image fit">
                  <img src={dsmhack} alt="" />
                </a>
                <header>
                  <h3>dsm Hack</h3>
                </header>
              </article>
            </div>
            <div className="col-4 col-12-mobile project">
              <article className="item">
                <a href="https://www.technologyiowa.org/Programs/ShowProgram?id=3" className="image fit">
                  <img src={tai} alt="" />
                </a>
                <header>
                  <h3>TAI (formerly HyperStream)</h3>
                </header>
              </article>
            </div>
            <div className="col-4 col-12-mobile project">
              <article className="item">
                <a href="http://www.wonderyearsacademy.org" className="image fit">
                  <img src={wya} alt="" />
                </a>
                <header>
                  <h3>Wonder Years Academy</h3>
                </header>
              </article>
            </div>
          </div>
        </div>
    );
}