import React from 'react';
import Layout from '../components/Layout';
import PageFooter from '../components/PageFooter';
import Main from '../components/Main';
import Portfolio from '../components/Portfolio';
import About from '../components/About'
import Contact from '../components/Contact'
import SideBar from "../components/Sidebar/index";
const sections = [
  { id: 'top', name: 'Intro', icon: 'fa-home' },
  { id: 'portfolio', name: 'Portfolio', icon: 'fa-th' },
  { id: 'about', name: 'About Me', icon: 'fa-user' },
  { id: 'contact', name: 'Contact', icon: 'fa-envelope' },
];

const IndexPage = () => (
  <Layout>
    <SideBar sections={sections} />

    <div id="main">
      <section id="top" className="one dark cover">
        <Main/>
      </section>

      <section id="portfolio" className="two">
        <Portfolio />
      </section>
      <section id="about" className="three">
        <About />
      </section>
      <section id="contact" className="four">
        <Contact />
      </section>
    </div>
    <PageFooter />
  </Layout>
);

export default IndexPage;
